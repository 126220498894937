<template>
  <div class="home">
    <div ref="mySwiper" class="swiper-container" id="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in (screenWidth > 850 && IMAGES_PC) || IMAGES_MOBILE"
          :key="index">
          <div class="mask-container">
            <template v-if="screenWidth > 850">
              <img src="/home/bannerswiper/mask.png" class="mask" />
            </template>

            <img :src="item" class="bannerImg" loading="eager" />

            <template v-if="screenWidth > 850">
              <div class="slogan-container">
                <div class="slogan-text-panel">
                  <span class="slogan-cn">{{ CAROUSEL_SLOTS.slots[index].sloganCn }}</span>
                  <span class="slogan-en">{{ CAROUSEL_SLOTS.slots[index].sloganEn }}</span>
                  <span class="description">{{ CAROUSEL_SLOTS.slots[index].description }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination"></div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { inject } from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";

// 从轮播图导入脚本导入；
// 该脚本在编译期根据后台设置的静态 json 文件 /public/hmoe/bannerswiper/carousels.json 生成；
import { IMAGES_PC, IMAGES_MOBILE, CAROUSEL_SLOTS } from "./carousels.js";
// import MASK from "/public/home/bannerswiper/mask.png";

export default {
  computed: {
    ...mapGetters(["screenWidth"]),
  },
  data() {
    return {
      photoArray: [1, 2, 3, 4, 5], //轮播图数组
      carouselPCArr: [],
      carouselMobileArr: [],

      IMAGES_PC: IMAGES_PC,
      IMAGES_MOBILE: IMAGES_MOBILE,
      CAROUSEL_SLOTS: CAROUSEL_SLOTS,

      // MASK: MASK,
    };
  },

  setup() {
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "home";
    pageIndicator.lightMode = false; // 导航栏背景适应深色内容模式；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；
    console.log("inject pageIndicator ...", pageIndicator);
  },

  methods: {

    //初始化swiper
    initSwiper() {
      // const perView=this.screenWidth>850&&4
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      this.currentSwiper = new Swiper("#mySwiper", {
        autoplay: true,
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        
        slidesPerView: 1, //视口展示slide数1
        pagination: {
          el: '.swiper-container .pagination',
          clickable: true,
        },

        effect: 'slide',
      });
    },
    //销毁swiper
    destroySwiper() {
      try {
        this.currentSwiper.destroy(true, true);
      } catch (e) {
        console.log("删除轮播");
      }
    },
    //更新swiper
    updateSwiper() {
      this.destroySwiper();
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 300);
  },
  unmounted() { },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100vh;

}
@media screen and (max-width: 850px){
  .home {
    height: auto;
  }
}


.bannerImg {
  /* width: 100%;
  height: 1080px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mask-container {
  position: relative;
  padding: 0px;
  height: 100%;
}

.mask {
  width: 100%;
  position: absolute;
  bottom: 1px;
}

.slogan-container {
  width: 100%;
  height: 265px;
  position: absolute;
  bottom: 4px;

  padding: 0px 90px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
}

.slogan-text-panel {
  width: 100%;
  max-width: 1740px;
  height: 100%;

  box-sizing: border-box;

  position: relative;
}

.slogan-cn {
  position: absolute;
  left: 0px;
  bottom: 140px;

  /* width: 393px; */
  height: 41px;
  font-size: 44px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 300;
  color: #ffffff;
  line-height: 80px;
  letter-spacing: 10px;
}

.slogan-en {
  position: absolute;
  left: 0px;
  bottom: 80px;

  /* width: 393px; */
  height: 41px;
  font-size: 20px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 300;
  color: #ffffff;

  line-height: 80px;
  letter-spacing: 3px;
}

.description {
  position: absolute;
  right: 0px;
  bottom: 140px;

  /* width: 393px; */
  height: 23px;
  font-size: 24px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 300;
  color: #ffffff;

  line-height: 50px;
  letter-spacing: 3px;
}

/* 
  -- 注释掉基于 element 组件的走马灯样式；--

.el-carousel__container:deep() {
  min-width: 600px;
  min-height: 700px;
}


.el-carousel__indicators--horizontal:deep() {
  left: auto;
  right: 0;
  bottom: 70px;
}


.el-carousel__indicator:deep() {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  padding: 0;
  overflow: hidden;
  border: 1px solid white;
  box-sizing: border-box;
  margin-right: 20px;
  background: white;
}

.el-carousel__indicator--horizontal.is-active:deep() {
  background: none;
}

.el-carousel__button:deep() {
  width: 0;
} */
.pagination{
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  padding: 0 90px;
  bottom: 80px;

}
@media screen and (max-width: 850px ) {
  .pagination{
    justify-content: center;
    padding: 0px; 
  }
}

.swiper-container{
  height: 100% !important;
}
.swiper-wrapper{
  height: 100% !important;
}
.swiper-slide{
  height: 100%;
}

:deep(.pagination .swiper-pagination-bullet) {
  display: inline-block !important;
  width: 9px !important;
  height: 9px !important;
  margin-left: 18px !important;
  background: #ffffff !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff;
  transition: width 0.2s !important;
  cursor: pointer !important;
}
  /* 被选中的分页 */
:deep(.pagination .swiper-pagination-bullet-active){
  width: 9px !important;
  height: 9px !important;
  background: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 4px !important;
}
</style>

